<template>
  <div class="searchResult">
    <p class="searchResultTitle">
      包含
      <span>关键词</span> 的结果
    </p>
    <div class="searchResultContent">
      <div class="item" v-for="i in 10" :key="i">
        <p>搜索结果 {{ i }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.searchResult {
  padding: 15px;
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 10px auto;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.searchResultTitle {
  font-size: 16px;
}
.searchResultTitle span {
  font-size: 18px;
  color: $commonThemeColor;
  font-weight: 700;
}
.searchResultContent {
  padding-top: 15px;
  line-height: 35px;
}
.searchResultContent p {
  color: #666666;
  border-bottom: 1px dashed #f2f2f2;
}
</style>
